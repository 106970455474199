<template>
  <div class="text-center mb-10">
    <img class="inline mb-8" src="/images/avatar.png" alt="" />
    <template v-if="miniSidebar === 'default'">
      <h3 class="font-bold text-white">Mashrafi Bin Mortuza</h3>
      <p class="text-white mt-3 text-base text-uppercase">{{ avatar.role }}</p>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    avatar: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      miniSidebar: (state) => state.sidebar,
    }),
  },
};
</script>

<style lang="scss"></style>
