<template>
  <md-dialog :md-active="active" :class="classes">
    <div class="p-4 delete_item">
      <h2 class="text-uppercase text-base m-8 text-center">{{ message }}</h2>
      <div class="flex justify-center m-8">
        <md-button class="md-dense m-1 bg-success rounded text-white" @click="$emit('update:active', !active)">Close</md-button>
        <md-button class="md-dense m-1 bg-danger rounded  text-white" @click="confirme">Confirm</md-button>
      </div>
    </div>
  </md-dialog>
</template>

<script>
export default {
  props: {
    active: Boolean,
    message: {
        type: String,
        default: () => 'Are you sure you want to delete this item ?'
    },
    classes: {
        type: String,
        default: () => ''
    },
  },
  methods: {
    confirme() {
      this.$emit("update:active", false);
      this.$emit("confirm");
      this.$emit("success" ,false);
    },
  },
};
</script>
