import api from '../../api';
// import router from '@/router'

export const actGetDashboard = ({ commit }) => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/dashboard`, null)
                .then( (response) => {
                    commit('setDashboard', response.countings);
                    resolve(response);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}




