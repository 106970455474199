<template>
  <div class="flex flex-col align-center w-full h-full images">
      <label class="mb-2 text-uppercase font-bold block"> Photo Upload </label>
      <div class="outline-gray-400 w-full h-full" v-if="this.handleData">
          <img class="md-upload__image" :src="imageData" alt="image"/>
      </div>
      <div class="outline-gray-400 rounded center w-full h-full p-2" v-bind:class="{ md__image_button:  handleData }">
          <div @click="$refs.imageInput.click()" class="py-4 px-8 inline-flex align-center rounded bg-victoria text-white pointer">
              <svg class="w-12 mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30" fill="#5B5B5B">
                  <path d="M12.236328 4C11.478328 4 10.786266 4.4274688 10.447266 5.1054688L10.052734 5.8945312C9.7137344 6.5715313 9.0206719 7 8.2636719 7L4 7C2.895 7 2 7.895 2 9L2 22C2 23.105 2.895 24 4 24L26 24C27.105 24 28 23.105 28 22L28 9C28 7.895 27.105 7 26 7L21.736328 7C20.978328 7 20.286266 6.5725312 19.947266 5.8945312L19.552734 5.1054688C19.213734 4.4284687 18.520672 4 17.763672 4L12.236328 4 z M 6 5C5.448 5 5 5.448 5 6L8 6C8 5.448 7.552 5 7 5L6 5 z M 15 8C18.86 8 22 11.14 22 15C22 18.86 18.86 22 15 22C11.14 22 8 18.86 8 15C8 11.14 11.14 8 15 8 z M 24 9C24.552 9 25 9.448 25 10C25 10.552 24.552 11 24 11C23.448 11 23 10.552 23 10C23 9.448 23.448 9 24 9 z M 15 10 A 5 5 0 0 0 10 15 A 5 5 0 0 0 15 20 A 5 5 0 0 0 20 15 A 5 5 0 0 0 15 10 z" fill="#fff" />
              </svg>
              <div class="pl-3 text-left">
                  <p class="text-lg">Upload</p>
                  <p class="text-lg">Image</p>
              </div>
              <input accept="image/*" type="file" class="hidden" ref="imageInput" @change="updateComponentImage">
          </div>
         
      </div>
       <b id="scrollTop" class="text-danger" v-if="message">{{ message }}</b>
  </div>
</template>

<script>
import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'

export default {
  props: {
      imageType: {
      type: String
      },
      message: {
        type: String,
        default: () => null
      },
      url: {
        type: String,
        default: ''
      }
  },
  data(){
      return{
          imageShow:"",
          handleData: this.url,
          result: '',
          link:this.$route.query.type,
          imageData: this.url || ""
      }
  },
  watch: {
    url(newValue) {
      this.imageData = newValue;
       if (newValue){
        this.handleData = true
       }
    }
  },
  methods: {
      updateComponentImage(e){
          let image_data = e.target.files[0];
          this.imageData = URL.createObjectURL(image_data);
          this.path = this.link+'/images';
          putObject(image_data, this.path, "public-read");
          this.Img_path = this.link+'/images/';

          const paths = this.Img_path+image_data.name;
          this.$emit('input',baseUrl+paths);

          if(this.imageData != null){
              this.handleData = true;
          }
      },
  }
}
</script>
