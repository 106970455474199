<template>
  <div :class="$route.meta.layout">
    <component :is="layout">
      <router-view></router-view>
    </component>
    <div class="mobile-drawer">
      <Drawer
        :items="navigationItems.menuItems"
        :avatar="navigationItems.avatar"
      />
    </div>
    <md-snackbar
      md-position="center"
      :md-duration="3000"
      :md-active.sync="showSnackbar"
    >
      <span>{{ snackbarMessage }}</span>
    </md-snackbar>
    <div>
    <!-- <Loader/> -->
    </div>
  </div>

</template>

<script>
const default_layout = "master";
import Drawer from "@/components/layouts/Drawer";
import masterItems from "@/data/sidebar/master/items";
// import Loader from './components/atom/loader.vue'
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Drawer,
    // Loader
  },
  computed: {
    ...mapState({
      snackbarMessage: (state) => state.snackbarMessage,
    }),
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    navigationItems() {
      let menuItems = [];
      let avatar = {};
      if (this.$route.meta.layout == "master") {
        menuItems = masterItems;
        avatar.role = "Super Admin";
      }
      return {
        menuItems,
        avatar,
      };
    },
    showSnackbar: {
      get() {
        return this.$store.state.showSnackbar;
      },
      set() {
        this.$store.commit("setCloseSnackbar");
      },
    },
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    ...mapMutations({
      setSidebar : 'setSidebar'
    }),
    myEventHandler() {

      if(window.innerWidth < 1150) {
        this.setSidebar('other')
      } else {
         this.setSidebar('default')
      }
    },
  },
};
</script>
