<template>
    <div v-if="loading" class="spinner-overlay rounded-lg">
      <md-progress-spinner 
        :md-diameter="diameter" 
        :md-stroke="stroke" 
        md-mode="indeterminate"
        :class="className"
        :style="{ stroke: spinnerColor }"
        >
      </md-progress-spinner>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Spinner',
    props: {
      loading: {
        type: Boolean,
        required: true,
      },
      diameter: {
        type: Number,
        default: 100,
      },
      stroke: {
        type: Number,
        default: 10,
      },
      className: {
        type: String,
        default: '',
      },
      spinnerColor: {
        type: String,
        default: '#040340',
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .spinner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080; 
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; 
        opacity: .5;
    }
  </style>
  