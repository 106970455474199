import api from '../../api';
// import router from '@/router'
export const actLogin = ({commit}, payload) => {
    return new Promise((resolve, reject) => {
        try {
            api('post', 'api/v1/login', payload, 'public')
                .then(response => {
                    commit('setUser', response);
                    resolve(response);
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}

export const actGetUserInformation = ({ commit } , userId) => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/creator-info/${userId}`)
                .then(({items}) => {
                    commit('setCreatorInformation', items)
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}

export const actGetAuthUser = ({ commit }) => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/auth-user`)
                .then((items) => {
                    
                    commit('setUser', items)
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}

export const actGetUserList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        try {
           
            api('get', `api/v1/users${query}`)
                .then( ({ items, meta }) => {
                    commit('setUserList', items);
                    commit('setUserMeta', meta);
                    commit('setSelectedUserList', items[0]);
                    resolve(items);
                    commit('setLoading', false);
                })
                .catch(error => { 
                    reject(error)
                    commit('setLoading', false);
                })
            } catch (error) {
                reject(error);
                commit('setLoading', false);
            }
    })
}

export const actGetUserByUserId = ({commit}, userId = '') => {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        try {
            api('get', `api/v1/users/${userId}`)
                .then( (response) => {
                    resolve(response)
                    commit('setLoading', false);
                })
                .catch(error => {
                    reject(error)
                    commit('setLoading', false);
                })
            } catch (error) {
                reject(error);
                commit('setLoading', false);
            }
    })
}

export const actDeleteUserByUserId = ({ commit } , userId) => {
    return new Promise((resolve, reject) => {
        try {
            api('delete', `api/v1/users/${userId}`)
                .then(({ message }) => {
                    commit('setRemoveDeletedItem', userId)
                    resolve(message);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actCreateNewUser = ( store , payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', 'api/v1/users', payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

 
export const actChangeStatusUser  = (store, {userId, payload}) => {
    return new Promise((resolve, reject) => {
        try {
            api('patch', `api/v1/users/change-status/${userId}`, payload)
                .then( response => {
                    resolve(response.message)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actUpdateUser = ( store, {UserId, payload})  => {
    return new Promise((resolve, reject) => {
        try {
            api('put', `api/v1/users/${UserId}`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actCountryList = () => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/country-list`)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actPostalCodesByCountry = (store, countryId) => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/postal-code/${countryId}`)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actPostalCodes = () => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/postal-code`)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}