let userType = localStorage.getItem('user_type');
const layout = userType?userType.toLowerCase():'master';
export const teacher = [   
  {
    path: 'question-creation',
    component: {
      render(c) { return c('router-view') }
    },
    meta: { layout: layout },
    redirect: { name: 'teacher.questionCreation' },
    children: [
      {
        path: '',
        name: 'teacher.questionCreation',
        component: () => import(/* webpackChunkName: "teacher-question-creation" */ '../../views/teacher/question-creation/IndexView.vue'),
        meta: { layout: layout },
      },
      {
        path: 'pte-question',
        name: 'teacher.pteQuestion',
        component: () => import(/* webpackChunkName: "teacher-pte-question" */ '../../views/teacher/question-creation/pte-question/IndexView.vue'),
        meta: { layout: layout },
        redirect: { name: 'pte-question.reading' },
        children: [
          {
            path: 'reading',
            name: 'pte-question.reading',
            component: () => import(/* webpackChunkName: "teacher-pte-question-reading" */ '../../views/teacher/question-creation/pte-question/ReadingView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'writing',
            name: 'pte-question.writing',
            component: () => import(/* webpackChunkName: "teacher-pte-question-writing" */ '../../views/teacher/question-creation/pte-question/WritingView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'speaking',
            name: 'pte-question.speaking',
            component: () => import(/* webpackChunkName: "teacher-pte-question-speaking" */ '../../views/teacher/question-creation/pte-question/SpeakingView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'listening',
            name: 'pte-question.listening',
            component: () => import(/* webpackChunkName: "teacher-pte-question-listening" */ '../../views/teacher/question-creation/pte-question/ListeningView.vue'),
            meta: { layout: layout }
          }
        ]
      },
      {
        path: 'pte-question/create',
        name: 'pte-question.create',
        component: () => import(/* webpackChunkName: "teacher-pte-question-create" */ '../../views/teacher/question-creation/pte-question/CreateView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'pte-question/edit/:id',
        name: 'pte-question.edit',
        component: () => import(/* webpackChunkName: "teacher-pte-question-edit" */ '../../views/teacher/question-creation/pte-question/CreateView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'pte-quiz',
        name: 'teacher.pteQuiz',
        component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../../views/teacher/question-creation/pte-quiz/QuizList.vue'),
        meta: { layout: layout }
      },
      {
        path: 'pte-quiz/create',
        name: 'pte-quiz.create',
        component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../../views/teacher/question-creation/pte-quiz/CreateQuiz.vue'),
        meta: { layout: layout }
      },
      {
        path: 'pte-quiz/edit/:id',
        name: 'pte-quiz.edit',
        component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../../views/teacher/question-creation/pte-quiz/CreateQuiz.vue'),
        meta: { layout: layout }
      }
    ]
  },
  {
    path: 'pte-question/create',
    name: 'pte-question.create',
    component: () => import(/* webpackChunkName: "teacher-pte-question-create" */ '../../views/teacher/question-creation/pte-question/CreateView.vue'),
    meta: { layout: layout }
  },
  {
    path: 'question-creation/pte-quiz',
    name: 'teacher.pteQuiz',
    component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../../views/teacher/question-creation/pte-quiz/QuizList.vue'),
    meta: { layout: layout }
  },
  {
    path: 'question-creation/pte-quiz/create',
    name: 'pte-quiz.create',
    component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../../views/teacher/question-creation/pte-quiz/CreateQuiz.vue'),
    meta: { layout: layout }
  },  
  {
    path: 'material',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'material.nav',
        component: () => import(/* webpackChunkName: "teacher-material-nav" */ '../../views/teacher/course-material/IndexView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'dashboard',
        name: 'material.dashboard',
        redirect: {name: 'material.dashboard.fromPlatform.all'},
        component: () => import(/* webpackChunkName: "teacher-material-dashboard" */ '../../views/teacher/course-material/DashboardView.vue'),
        meta: { layout: layout },
        children: [
          {
            path: 'from-platform',
            name: 'material.dashboard.fromPlatform',
            component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/FromPlatform.vue'),
            meta: { layout: layout },
            redirect: {name: 'material.dashboard.fromPlatform.all'},
            children: [
              {
                path: 'all',
                name: 'material.dashboard.fromPlatform.all',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'pdf-file',
                name: 'material.dashboard.fromPlatform.pdfFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'word-file',
                name: 'material.dashboard.fromPlatform.wordFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'image-file',
                name: 'material.dashboard.fromPlatform.imageFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'audio-file',
                name: 'material.dashboard.fromPlatform.audioFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'video-file',
                name: 'material.dashboard.fromPlatform.videoFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
            ]
          },
          {
            path: 'from-student',
            name: 'material.dashboard.fromStudent',
            component: () => import(/* webpackChunkName: "teacher-material-dashboard-grid" */ '../../views/teacher/course-material/dashboard/FromStudent.vue'),
            meta: { layout: layout },
            redirect: {name: 'material.dashboard.fromStudent.all'},
            children: [
              {
                path: 'all',
                name: 'material.dashboard.fromStudent.all',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'pdf-file',
                name: 'material.dashboard.fromStudent.pdfFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'word-file',
                name: 'material.dashboard.fromStudent.wordFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'image-file',
                name: 'material.dashboard.fromStudent.imageFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'audio-file',
                name: 'material.dashboard.fromStudent.audioFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              },
              {
                path: 'video-file',
                name: 'material.dashboard.fromStudent.videoFile',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../../views/teacher/course-material/dashboard/ListView.vue'),
                meta: { layout: layout },
              }
            ]
          }
        ]
      },
      {
        path: 'group',
        name: 'material.group',
        component: () => import(/* webpackChunkName: "teacher-material-group" */ '../../views/teacher/course-material/GroupView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'course-wise',
        name: 'material.courseWise',
        component: () => import(/* webpackChunkName: "teacher-material-course-wise" */ '../../views/teacher/course-material/CourseWiseView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'special',
        name: 'material.special',
        component: () => import(/* webpackChunkName: "teacher-material-special" */ '../../views/teacher/course-material/SpecialView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'word-list',
        name: 'material.wordList',
        component: () => import(/* webpackChunkName: "teacher-material-word-list" */ '../../views/teacher/course-material/WordListView.vue'),
        meta: { layout: layout }
      },
    ]
  },
  {
    path: 'study-plan',
    name: 'studyPlan',
    component: () => import(/* webpackChunkName: "teacher-study-plan" */ '../../views/teacher/study-plan/IndexView.vue'),
    meta: { layout: layout },
    redirect: { name: 'studyPlan.achieves' },
    children: [
      {
        path: 'achieves',
        name: 'studyPlan.achieves',
        component: () => import(/* webpackChunkName: "teacher-study-plan-achieves" */  '../../views/teacher/study-plan/AchievesView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'list',
        name: 'studyPlan.list',
        component: () => import(/* webpackChunkName: "teacher-study-plan-list" */ '../../views/teacher/study-plan/PlanListView.vue'),
        meta: { layout: layout }
      },
      {
        path: 'calendar',
        name: 'studyPlan.calendar',
        component: () => import(/* webpackChunkName: "teacher-study-plan-calendar" */ '../../views/teacher/study-plan/PlanCalendarView.vue'),
        meta: { layout: layout }
      }

    ]
  },
]