import { teacher } from './teacher';
import { testCreation } from './testCreation';
import { questionAndTestManagement } from './questionAndTestManagement';
import { branchManagement } from './branchManagement';
let userType = localStorage.getItem('user_type');
const layout = userType?userType.toLowerCase():'master';

export const master = [
    {
      path: '/',
      redirect: '/master',
      name: 'root'
    },
    {
      path: '/master',
      name: 'master',
      component: {
        render(c) { return c('router-view') }
      },
      redirect: {name: 'master.adminPortal'},
      children: [
        {
          path: 'admin-portal',
          name: 'master.adminPortal',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/MasterPortalView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'coaching-center',
          component: {
            render(c) { return c('router-view') }
          },
          redirect: {name: 'master.coachingCenter'},
          meta: { layout: layout },
          children:[
            {
              path: '',
              name: 'master.coachingCenter',
              component: () => import(/* webpackChunkName: "Coaching-Center" */ '../../views/master/coaching-center/IndexView.vue'),
              meta: { layout: layout },
            },
            {
              path: 'create',
              name: 'master.createCoachingCenter',
              component: () => import(
                  /*webpackChunkName: "basic-information" */
                  '../../components/molecule/coaching-center/CoachingCreateForm.vue'
              ),
              meta: { layout: layout },
            },
            {
              path: 'edit/:id',
              name: 'master.editCoachingCenter',
              component: () => import(/* webpackChunkName: "master-portal" */ '../../components/molecule/coaching-center/CoachingCreateForm.vue'),
              meta: { layout: layout },
            },
          ]
        },  
        {
            path: 'question-management',
            name: 'master.questionManagement',
            component: {
                render(c) { return c('router-view') }
            },
            meta: { layout: 'master' },
            redirect: {name: 'teacher.questionCreation'},
            children: [
              ...teacher,
            ]
        },    
        {
            path: 'test-management',
            name: 'master.testManagement',
            component: {
                render(c) { return c('router-view') }
            },
            meta: { layout: 'master' },
            redirect: { name: 'teacher.testCreation' },
            children: [
              ...testCreation,
            ]
        },   
        ...questionAndTestManagement,
        ...branchManagement,
        {
          path: 'our-student',
          component: {
            render(c) { return c('router-view') }
          },
          meta: {layout: layout},
          redirect: {name: 'master.ourStudent'},
          children:[
            {
              path: '',
              name: 'master.ourStudent',
              component: () => import(/* webpackChunkName: "student" */ '../../views/master/student-management/IndexView.vue'),
              meta: { layout: layout }
            },
            {
              path: 'create',
              name: 'master.createStudent',
              component: () => import(/* webpackChunkName: "master-portal" */ '../../components/molecule/user/UserCreateForm.vue'),
              meta: { layout: layout },
              props: {
                componentType: 'STUDENT',
              },
            },
            {
              path: 'edit/:id',
              name: 'master.editStudent',
              component: () => import(/* webpackChunkName: "master-portal" */ '../../components/molecule/user/UserCreateForm.vue'),
              meta: { layout: layout },
              props: {
                componentType: 'STUDENT',
              },
            },
          ]
        },
        {
          path: 'administrative-roles',
          component: {
            render(c) { return c('router-view') }
          },
          meta: { layout: layout },
          redirect: {name: 'master.administrativeRoles'},
          children:[
            {
              path: '',
              name: 'master.administrativeRoles',
              component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/administrativeRoles/IndexView.vue'),
              meta: { layout: layout },
            },
            {
              path: 'create',
              name: 'master.createAdministrative',
              component: () => import(/* webpackChunkName: "master-portal" */ '../../components/molecule/user/UserCreateForm.vue'),
              meta: { layout: layout },
              props: {
                componentType: 'SUPER_ADMIN',
              },
            },
            {
              path: 'edit/:id',
              name: 'master.editAdministrative',
              component: () => import(/* webpackChunkName: "master-portal" */ '../../components/molecule/user/UserCreateForm.vue'),
              meta: { layout: layout },
              props: {
                componentType: 'SUPER_ADMIN',
              },
            },
          ]
        },
        {
          path: 'study-plan',
          name: 'master.studyPlan',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/study-plan/IndexView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'dummy-coching',
          name: 'master.dummyCoching',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/dummy-coching-center/IndexView.vue'),
          meta: { layout: layout }
        },
       
      ]
    }
];