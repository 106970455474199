
export const questionAndTestManagement = [
  
 
    /*
        03. ======= Test Management Routes Start ========
    */
    {
        path: 'test-management',
        name: 'master.QATM.testManagement',
        redirect: {name: 'master.QATM.testManagement.list'},
        component: () => import(
            /* webpackChunkName: "master-QATM-test-management" */
            '../../views/master/question-and-test-management/test-management/IndexView.vue'
            ),
        meta: { layout: 'master' },
        children: [
            {
                path: 'list',
                name: 'master.QATM.testManagement.list',
                component: () => import(
                    /* webpackChunkName: "master-QATM-test-management" */
                    '../../views/master/question-and-test-management/test-management/ListView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },
            {
                path: 'assign-to-branch',
                name: 'master.QATM.testManagement.assignToBranch',
                component: () => import(
                    /* webpackChunkName: "master-QATM-test-management" */
                    '../../views/master/question-and-test-management/test-management/AssignToBranchView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },
            {
                path: 'draft',
                name: 'master.QATM.testManagement.draft',
                component: () => import(
                    /* webpackChunkName: "master-QATM-test-management" */
                    '../../views/master/question-and-test-management/test-management/DraftView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },
        ]
    },
    /*
        04. ======= Material Management Routes Start ========
    */
    {
        path: 'material-management',
        name: 'master.QATM.materialManagement',
        redirect: {name: 'master.QATM.materialManagement.list'},
        component: () => import(
            /* webpackChunkName: "master-QATM-material-management" */
            '../../views/master/question-and-test-management/material-management/IndexView.vue'
            ),
        meta: { layout: 'master' },
        children: [
            {
                path: 'list',
                name: 'master.QATM.materialManagement.list',
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/ListView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },
            {
                path: 'assign-to-branch',
                name: 'master.QATM.materialManagement.assignToBranch',
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/AssignToBranchView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },
            {
                path: 'draft',
                name: 'master.QATM.materialManagement.draft',
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/DraftView.vue'
                    ),
                meta: { layout: 'master' },
            },
            {
                path: 'explanation',
                name: 'master.QATM.materialManagement.explanation',
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/ExplanationView.vue'
                    ),
                meta: { layout: 'master' },
            },
            {
                path: 'study-guide',
                name: 'master.QATM.materialManagement.study-guide',
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/StudyGuide.vue'
                    ),
                meta: { layout: 'master' },
            },
            {
                path: 'sample-answer',
                name: 'master.QATM.materialManagement.sample-answer',
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/SampleAnswer.vue'
                    ),
                meta: { layout: 'master' },
            },
        ]
    },
    /*
        05. ======= Quiz Management Routes Start ========
    */
    {
        path: 'quiz-management',
        name: 'master.QATM.quizManagement',
        redirect: {name: 'master.QATM.quizManagement.list'},
        component: () => import(
            /* webpackChunkName: "master-QATM-quiz-management" */
            '../../views/master/question-and-test-management/quiz-management/IndexView.vue'
            ),
        meta: { layout: 'master' },
        children: [
            {
                path: 'list',
                name: 'master.QATM.quizManagement.list',
                component: () => import(
                    /* webpackChunkName: "master-QATM-quiz-management" */
                    '../../views/master/question-and-test-management/quiz-management/ListView.vue'
                    ),
                meta: { layout: 'master' },                                                                                     
            },
            {
                path: 'assign-to-branch',
                name: 'master.QATM.quizManagement.assignToBranch',
                component: () => import(
                    /* webpackChunkName: "master-QATM-quiz-management" */
                    '../../views/master/question-and-test-management/quiz-management/AssignToBranchView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },
            {
                path: 'draft',
                name: 'master.QATM.quizManagement.draft',
                component: () => import(
                    /* webpackChunkName: "master-QATM-quiz-management" */
                    '../../views/master/question-and-test-management/quiz-management/DraftView.vue'
                    ),
                meta: { layout: 'master' },                                                                                         
            },

        ]
    },
]
   
