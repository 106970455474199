import Vue from 'vue'
import VueRouter from 'vue-router'
import { master } from './master'
import { common } from './common'
import { course } from './course'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes : [
    ...master,
    ...common,
    ...course,
  ],
  
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

let excepts = ['login', 'registration'];


router.beforeEach((to, from, next) => {
  var firstSegment = window.location.pathname.split('/')[1];
  var userType = localStorage.getItem('user_type')
  
  if(userType){
    if(userType.toLowerCase() !== firstSegment && (userType == 'master')){
      window.location.replace('/'+userType.toLowerCase()+'/admin-portal');
    }
  }
  let defaultLayout = to.meta.layout || 'login';

  if (!excepts.includes(defaultLayout)) {
    let token = localStorage.getItem('token');
    if (!token) {
       next('/login')
    }
  }
  console.log(userType, firstSegment, 'firstSegment',defaultLayout)
  next();
});

export default router;
