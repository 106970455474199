import api from '../../api';

export const actGetOrganizationList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        try {
            api('get', `api/v1/organizations${query}`, null)
                .then( ({ items, meta }) => {
                    commit('setOrganizationList', items);
                    commit('setOrganizationMeta', meta);
                    commit('setSelectedOrganizationList', items[0]);
                    resolve(items);
                    commit('setLoading', false); 
                })
                .catch(error => {
                    reject(error)
                    commit('setLoading', false); 
                })
            } catch (error) {
                reject(error);
                commit('setLoading', false); 
            } 
    })
}

export const actSaveOrganization = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/organizations`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actUpdateOrganization = ( store, {OrganizationId, payload})  => {
    return new Promise((resolve, reject) => {
        try {
            api('put', `api/v1/organizations/${OrganizationId}`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actDeleteOrganization = ({ commit } , OrganizationId) => {
    return new Promise((resolve, reject) => {
        try {
            api('delete', `api/v1/organizations/${OrganizationId}`)
                .then(({ message }) => {
                    commit('setRemoveDeletedItem', OrganizationId)
                    resolve(message);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetOrganizationByOrganizationId = ({ commit }, OrganizationId = '') => {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        try {
            api('get', `api/v1/organizations/${OrganizationId}`, null)
                .then( (response) => {
                    resolve(response)
                    commit('setLoading', false); 
                })
                .catch(error => {
                    reject(error)
                    commit('setLoading', false); 
                })
            } catch (error) {
                reject(error);
                commit('setLoading', false); 
            } 
    })
}


export const actChangeStatusOrganization  = (store, {organizationId, payload}) => {
    return new Promise((resolve, reject) => {
        try {
            api('patch', `api/v1/organizations/change-status/${organizationId}`, payload)
                .then( response => {
                    resolve(response.message)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}