<template>
  <div
    class="inline-flex"
    :class="[
        inline ? 'flex-row align-center': 'flex-col',
  ]">
    <label
      v-if="label"
      class="pr-4 inline-block mb-1 text-uppercase font-bold"
      :class="[
        requiredLabel ? 'required' : '',
        italic ? 'text-italic' : '',
      ]"
    >
      {{ label }}
    </label>
    <div class="flex flex-col basis relative">
      <select
        :disabled="disabled"
        class="select-component input-box-shadow pl-3 pr-8 pointer"
        :class="[
          borderNone ? 'border-none': 'outline-gray-400', ...classes
        ]"
        :value="value"
        @input="onChangeSelect"
      >
        <option v-if="placeholder" :value="null" disabled>{{ placeholder }}</option>
        <option class="options" :value="item.id" v-for="item in items" :key="item.id">
          {{ item.name }}
        </option>
      </select>
      <md-icon class="arrow-icon" :class="[iconClass]">arrow_drop_down</md-icon>
      <b id="scrollTop" class="text-danger" v-if="message">{{ message }}</b>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      movie: "",
      select: "",
      notFound: [
        {id: 0, name: 'Item not found'}
      ]
    };
  },
  props: {
    classes: {
      type: Array,
      default: () => { return ['py-3']}
    },
    label: {
      type: String,
      default: () => null,
    },
    iconClass: {
      type: String,
      default: () => null,
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    inline: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: [String, Boolean],
      default: () => 'Select one'
    },
    borderNone: {
      type: Boolean,
      default: () => false
    },
    italic: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    items: {
      type: Array,
      required: false,
      default: () => [
        { id: 1, name: "Item 01" },
        { id: 2, name: "Item 02" },
        { id: 3, name: "Item 03" },
        { id: 4, name: "Item 04" },
      ],
    },
    selected: {
      default: function() {
        if(this.items.length > 0) {
          return this.items[0].id;
        }
      }
    },
    value: {
      type:  [String, Number, Object, Array],
      default: function() {
        if(!this.placeholder && this.items.length > 0) {
          return this.items[0].id;
        }
      }
    },
    returnType: {
      type: String,
      default: () => 'id'
    },
    
  },
  methods: {
    onChangeSelect(e) {
      let value = e.target.value;
      if(this.returnType == 'object') {
        value = this.items.find(item => item.id == value);
        this.$emit('getObject', value);
      }else if(this.returnType == 'index') {
        this.$emit('getIndex', this.items.findIndex(item => item.id == value));
      }
      else {
        this.$emit('input', value);
      } 
    }
  },
  validations: {
    select: {
      required,
    },
  },
};
</script>

<style lang="scss">
.select-component {
  border-radius: 5px;
  outline: none;
  background-color: inherit;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.arrow-icon {
  position: absolute;
  top: 10px;
  right: 3px;
  margin: 0;
  pointer-events: none;
}
</style>
