// src/hooks/useFileUpload.js
import { putObject } from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const baseUrl = `${spacesBaseUrl.protocol}//${process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET}.${spacesBaseUrl.host}/${process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER}/`;

export default function useFileUpload() {
  const uploadToDigitalOcean = async (file, type) => {
    try {
      const path = `${type}/images`;
      await putObject(file, path, "public-read");
      return `${baseUrl}${path}/${file.name}`;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };

  return {
    uploadToDigitalOcean
  };
}
