<template>
    <div>
        <Alert background-color="#514b99" classObject="rounded-lg p-2 d-bg-admin">
            <div class="flex flex-wrap justify-between justify-sm-center justify-xs-center">
                <div class="mx-2 p-2">
                    <div class="text-white text-sm-center text-xs-center">
                        <h3 class="opacity-75 pb-2 text-uppercase">{{ $t(label) }}</h3>
                        <div>
                            <span class="font-bold text-uppercase text-xl">{{ currentDay }}</span>
                            <small class="pl-2">{{ currentDate }}</small>
                        </div>
                    </div>
                </div>
                <div class="mx-2 flex align-center">
                    <div class="outline-gray-600 p-3 rounded-lg text-white">
                        <span class="text-3xl font-semibold">{{ currentTime }}</span>
                    </div>
                </div>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert } from '@/components';

export default {
    components: {
        Alert
    },
    props: {
        label: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentDate: new Date().toLocaleDateString('default', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }),
            currentDay: new Date().toLocaleDateString('default', {
                weekday: 'short'
            }),
            currentTime: this.getFormattedTime()
        };
    },
    methods: {
        getFormattedTime() {
            return new Date().toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
        },
        updateTime() {
            this.currentTime = this.getFormattedTime();
        }
    },
    mounted() {
        this.timeInterval = setInterval(this.updateTime, 1000);
    },
    beforeDestroy() {
        clearInterval(this.timeInterval);
    }
};
</script>
