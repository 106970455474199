<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
          label="Address Line 1"
          :requiredLabel="true"
          v-model.trim="form.address"
          :message="$v.form.address.$dirty && !$v.form.address.required ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
          label="Address Line 2"
          v-model.trim="form.address2"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <SelectComponent
          :items="countries"
          label="Country"
          :requiredLabel="true"
          class="w-full mb-2"
          :selected="form.country_id"
          v-model="form.country_id"
          @input="onCountryInputChange"
          :message="$v.form.country_id.$dirty && !$v.form.country_id.required ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <SelectComponent
          :items="postalCodes"
          :requiredLabel="true"
          label="Postal Code"
          class="w-full mb-2"
          return-type="object"
          :selected="form.postal_code_id"
          v-model.trim="form.postal_code_id"
          @getObject="setPostalCode"
          :message="$v.form.postal_code_id.$dirty && !$v.form.postal_code_id.required ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout mt-5">
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
          label="State"
          v-model.trim="form.state"
        />
      </div>
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
          label="City"
          v-model.trim="form.city"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { InputFieldComponent, SelectComponent } from "@/components";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "BasicInformation",

  components: {
    InputFieldComponent,
    SelectComponent,
  },

  data() {
    return {
      loading: false,
      orderCountries: false,
      countries: [],
      postalCodes: [],
      otherCountries: [],
      form: {
        address: "",
        address2: "",
        postal_code_id: "70ede33a-cb5b-4be9-a3d0-87774ef94db5",
        country_id: "",
        state: "",
        city: "",
      },
    };
  },

  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
      actCountryList: "user/actCountryList",
      actPostalCodesByCountry: "user/actPostalCodesByCountry",
    }),

    setPostalCode(postalCode) {
      this.form.postal_code_id = postalCode.id;
      this.form.state = postalCode.state;
      this.form.city = postalCode.city;
    },

    onCountryInputChange(countryId) {
        this.getPostalCodes(countryId);
        this.orderCountries = false;
        const countryDetails = this.getCountryById(countryId);
        this.form.country_id = countryDetails?.id || "";
        this.form.country = countryDetails?.name || "";
    },

    getPostalCodes(countryId) {
      if (!countryId) return;
      this.actPostalCodesByCountry(countryId).then(({ items }) => {
        this.postalCodes = items;
        if (items.length > 0) {
          this.setPostalCode(items[0]);
        } else {
          this.resetLocationFields();
        }
      });
    },

    getCountryById(countryId) {
      return this.countries.find(country => country.id === countryId);
    },

    setOtherCountry() {
      this.orderCountries = true;
      const otherCountry = this.otherCountries[0];
      if (otherCountry) {
        this.form.country_id = otherCountry.id;
        this.form.country = otherCountry.name;
        this.getPostalCodes(otherCountry.id);
      } else {
        this.resetLocationFields();
      }
    },

    resetLocationFields() {
      this.form.postal_code_id = "";
      this.form.state = "";
      this.form.city = "";
    },

    setAddress(addressData) {
      this.form = {
        ...this.form,
        ...addressData,
      };
    },

    getAddress() {
      return this.form;
    },

    validateForm() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },

  validations: {
    form: {
      address: { required },
      country_id: { required },
      state: { required },
      city: { required },
      postal_code_id: { required },
    },
  },
  watch: {
      'form.address'() {
        this.$v.form.address.$touch();
      },
      'form.country_id'() {
          this.$v.form.country_id.$touch();
      },
      'form.postal_code_id'() {
          this.$v.form.postal_code_id.$touch();
      },
  }, 
  created() {
    this.actCountryList().then(({ main, others }) => {
      this.countries = [...main];
      this.otherCountries = others;
      if (this.countries.length > 0) {
        const firstCountry = this.countries[0];
        this.form.country_id = firstCountry.id;
        this.form.country = firstCountry.name;
        this.getPostalCodes(firstCountry.id);
      }
    });
  },
};
</script>
