let userType = localStorage.getItem('user_type');
const layout = userType?userType.toLowerCase():'master';
export const testCreation = [   
  {
    path: 'test-creation',
    name: 'master.testManagement',
    component: {
      render(c) { return c('router-view') }
    },
    redirect: { name: 'teacher.testCreation' }, // Need to navigate for mock-exam and practice-test
    children: [
      {
        path: '',
        name: 'teacher.testCreation',
        component: () => import(/* webpackChunkName: "teacher-test-creation" */ '../../views/teacher/test-creation/IndexView.vue'),
        meta: {layout: layout}
      },
      {
        path: 'list',
        name: 'testCreation.list',
        component: () => import(/* webpackChunkName: "teacher-test-creation" */ '../../views/student/pte-practice/PtePracticeCollapseView.vue'),
        meta: {layout: layout}
      },
      {
        path: 'quiz-list',
        name: 'quizCreation.list',
        component: () => import(/* webpackChunkName: "teacher-test-creation" */ '../../views/student/quiz-test/QuizTestCollapseView.vue'),
        meta: {layout: layout}
      },
      {
        path: 'create',
        name: 'testCreation.create',
        component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/IndexView.vue'),
        meta: {layout: layout},
        redirect: { name: 'testCreation.testInformation' },
        children: [
          {
            path: 'test-information',
            name: 'testCreation.testInformation',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/TestInformationView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'time-set',
            name: 'testCreation.timeSet',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/TimeSetView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'create-type',
            name: 'testCreation.createType',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/CreateTypeView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'number-of-question',
            name: 'testCreation.numberOfQuestion',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/NumberOfQuestionView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'choose-question',
            name: 'testCreation.chooseQuestion',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/ChooseQuestionView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'review',
            name: 'testCreation.review',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/ReviewView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'test-preview',
            name: 'testCreation.testPreview',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/create/TestPreviewView.vue'),
            meta: {layout: layout},
          },
          
        ]
      },
      {
        path: 'create',
        name: 'quizCreation.create',
        component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/IndexView.vue'),
        meta: {layout: layout},
        redirect: { name: 'quizCreation.testInformation' },
        children: [
          {
            path: 'test-information',
            name: 'quizCreation.testInformation',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/TestInformationView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'time-set',
            name: 'quizCreation.timeSet',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/TimeSetView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'create-type',
            name: 'quizCreation.createType',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/CreateTypeView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'number-of-question',
            name: 'quizCreation.numberOfQuestion',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/NumberOfQuestionView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'choose-question',
            name: 'quizCreation.chooseQuestion',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/ChooseQuestionView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'review',
            name: 'quizCreation.review',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/ReviewView.vue'),
            meta: {layout: layout},
          },
          {
            path: 'quiz-set-preview',
            name: 'quizCreation.testPreview',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../../views/teacher/test-creation/quiz-test/create/TestPreviewView.vue'),
            meta: {layout: layout},
          },
          
        ]
      },
      {
        path: 'practice-test',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'practiceTest',
            component: () => import(/* webpackChunkName: "teacher-test-creation-index" */ '../../views/teacher/test-creation/practice-test/IndexView.vue'),
            meta: { layout: layout },
          },
          {
            path: 'all',
            name: 'dataList.all',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-all" */ '../../views/teacher/test-creation/practice-test/data-list/AllView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'reading',
            name: 'dataList.reading',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-reading" */ '../../views/teacher/test-creation/practice-test/data-list/ReadingView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'writing',
            name: 'dataList.writing',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-writing" */ '../../views/teacher/test-creation/practice-test/data-list/WritingView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'speaking',
            name: 'dataList.speaking',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-speaking" */ '../../views/teacher/test-creation/practice-test/data-list/SpeakingView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'listening',
            name: 'dataList.listening',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-listening" */ '../../views/teacher/test-creation/practice-test/data-list/ListeningView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'combo',
            name: 'dataList.combo',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-combo" */ '../../views/teacher/test-creation/practice-test/data-list/ComboView.vue'),
            meta: { layout: layout }
          },
          {
            path: 'create',
            name: 'dataList.create',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-combo" */ '../../views/teacher/test-creation/practice-test/CreateView.vue'),
            meta: { layout: layout }
          },
          
          {
            path: 'show/:id',
            name: 'dataList.show',
            component: () => import(/* webpackChunkName: "teacher-test-creation-data-combo" */ '../../views/teacher/test-creation/practice-test/ShowView.vue'),
            meta: { layout: layout }
          },

        ]
      },
      {
        path: 'mock-exam',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'mockExam',
            component: () => import(/* webpackChunkName: "teacher-test-creation-index" */ '../../views/teacher/test-creation/mock-exam/IndexView.vue'),
            meta: { layout: layout },
          },
          {
            path: 'full-mock',
            name: 'mockExam.FullMock',
            component: () => import(/* webpackChunkName: "teacher-test-creation-index" */ '../../views/teacher/test-creation/mock-exam/data-list/FullMockListView.vue'),
            meta: { layout: layout },
          }
        ]
      },
    ]
  },
]