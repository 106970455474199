<template>
  <div class="avatar-container" @click="triggerFileUpload" :style="{ width: size, height: size }">
    <md-avatar :style="{ width: size, height: size, overflow: 'visible' }">
      <img 
        v-if="imageLoaded && avatarSrc" 
        :src="avatarSrc" 
        alt="Avatar"
        @load="onImageLoad"
        @error="onImageError"
      />
      <div v-else class="spinner-overlay rounded-full">
        <md-progress-spinner 
          :md-diameter="30" 
          :md-stroke="3" 
          md-mode="indeterminate"
          :style="{stroke: '#060553'}"
        />
      </div>
      <md-icon class="edit-icon bg-victoria">edit</md-icon>
    </md-avatar>
    <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;" accept="image/*" />
  </div>
</template>

<script>
import useFileUpload from "@/utils/useFileUpload";

export default {
  props: {
    size: {
      type: String,
      default: '100px'
    },
    imageType: {
      type: String
    },
    imageUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      avatarSrc: this.imageUrl || '/images/man.png',
      loading: false,
      imageLoaded: true, // Initially true for default image
      imageError: false
    };
  },
  watch: {
    imageUrl(newVal) {
      if (!newVal) {
        // If imageUrl is null or empty, set to default image and consider it loaded
        this.avatarSrc = '/images/man.png';
        this.imageLoaded = true;
        this.loading = false;
      } else {
        this.avatarSrc = newVal;
        this.imageLoaded = false;
        this.loadImage(newVal);
      }
    }
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.loading = true;
        this.imageLoaded = false;
        const { uploadToDigitalOcean } = useFileUpload();
        uploadToDigitalOcean(file, this.$route.query.type)
          .then(imgPath => {
            if (imgPath) {
              this.avatarSrc = imgPath;
              this.$emit('avatar-updated', imgPath);
              this.loadImage(imgPath);
            }
          })
          .catch(error => {
            console.error('Upload failed:', error);
            this.loading = false;
          });
      }
    },
    loadImage(src) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        this.imageLoaded = true;
        this.loading = false;
      };
      img.onerror = () => {
        console.error('Image load error');
        this.imageError = true;
        this.loading = false;
      };
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
    onImageError() {
      this.imageError = true;
      this.loading = false;
    }
  },
  mounted() {
    if (this.avatarSrc !== '/images/man.png') {
      this.loadImage(this.avatarSrc);
    }
  }
};
</script>

<style scoped>
.avatar-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.avatar-container img {
  border-radius: 50%;
  border: 1px solid #999999;
}
.edit-icon {
  position: absolute;
  top: 20px !important;
  right: -20px !important;
  left: auto !important;
  color: white !important;
  border-radius: 50%;
  padding: 15px;
  font-size: 20px !important;
}
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  opacity: .5;
}
</style>
