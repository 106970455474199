import { filter } from 'lodash';
export const setOrganizationList = (state, organizationList) => {
    state.organizationList = organizationList;
}
export const setOrganizationMeta = (state, organizationMeta) => {
    state.organizationMeta = organizationMeta;
}
export const setSelectedOrganizationList = (state, organizationList) => {
    state.selectedOrganizationList = organizationList
}
export const setRemoveDeletedItem = (state, organizationId) => {
    state.organizationList = filter(state.organizationList, (item) => item.id !== organizationId);
}

export const setLoading = (state, isLoading) => {
    state.isLoading = isLoading; 
}